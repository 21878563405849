// menu dark and light
const storageKey: string = 'theme-preference';

const onClick = (): void => {
  theme.value = theme.value === 'light' ? 'dark' : 'light';
  setPreference();
};

const getColorPreference = (): string => {
  const storedPreference = localStorage.getItem(storageKey);
  if (storedPreference) return storedPreference;

  return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
};

const setPreference = (): void => {
  localStorage.setItem(storageKey, theme.value);
  reflectPreference();
};

const reflectPreference = (): void => {
  const rootElement = document.firstElementChild;
  const themeToggle = document.querySelector('#theme-toggle');

  if (rootElement) {
    rootElement.setAttribute('data-theme', theme.value);
  }

  if (themeToggle) {
    themeToggle.setAttribute('aria-label', theme.value);
  }
};

const theme = {
  value: getColorPreference(),
};

reflectPreference();

window.onload = () => {
  reflectPreference();
  const themeToggle = document.querySelector('#theme-toggle');
  if (themeToggle) {
    themeToggle.addEventListener('click', onClick);
  }
};

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', ({matches: isDark}) => {
  theme.value = isDark ? 'dark' : 'light';
  setPreference();
});
// ---------------------- END