// Estendi l'interfaccia Window globale per includere la proprietà 'angular'.
declare global {
    interface Window {
        angular?: any;
    }
}

function cleanTitle(title: string): string {
    return title.replace(/\s+/g, '-').toLowerCase().replace(/[?!,.:;]/g, '');
}

document.addEventListener('DOMContentLoaded', () => {
    if (window.angular) { return; }

    const container = document.querySelector('.oxel_toc__container') as HTMLElement;
    const headers = document.querySelectorAll<HTMLElement>('#main_article h2, #main_article h3, #main_article h4, #main_article h5, #main_article h6');
    let number = 0;

    headers.forEach(header => {
        if (header.closest('[toc-skip]')) { return; }

        if (!header.id) {
            header.id = cleanTitle(header.textContent || '');
        }

        number++;
        const li = document.createElement('li');
        const tocItem = document.createElement('a');
        tocItem.textContent = header.textContent || '';
        tocItem.href = '#' + header.id;
        tocItem.id = tocItem.id + '-' + number;
        tocItem.setAttribute('aria-label', header.textContent || '');
        li.appendChild(tocItem);
        container.appendChild(li);
    });

    window.addEventListener('scroll', () => {
        headers.forEach(header => {
            const rect = header.getBoundingClientRect();
            const isVisible = (rect.top >= 0) && (rect.bottom <= window.innerHeight);
            const tocLink = document.querySelector<HTMLAnchorElement>('.oxel_toc__container a[href="#' + header.id + '"]');
            if (isVisible) {
                tocLink?.classList.add('attivo');
            } else {
                tocLink?.classList.remove('attivo');
            }
        });
    });
});

export {}; //
